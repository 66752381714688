<template>
  <div>
    <h3 class="pt-5">{{ txt }}</h3>
  </div>
</template>

<script>
import firebase from "../db";
import "firebase/database";
import { createDoc1, createDoc2 } from "../PDF/config.js";

const dbRefDocuments = firebase.database().ref("documents");
const dbRefInfo = firebase.database().ref("information");
export default {
  name: "PreviewPDF",
  data: function () {
    return {
      txt: "กรุณารอซักครู่...",
      info: "",
      pdf: "",
      result: "",
    };
  },
  created: async function () {
    if (this.$route.params.param == null) {
      this.txt = "ไม่พบข้อมูล";
    }

    await dbRefInfo
      .get()
      .then((snapshot) => {
        if (snapshot.exists()) {
          this.info = snapshot.val();
        } else {
          console.log("No data available");
        }
      })
      .catch((error) => {
        console.error(error);
      });

    await dbRefDocuments
      .child(this.$route.params.param)
      .get()
      .then((snapshot) => {
        if (snapshot.exists()) {
          this.pdf = snapshot.val();
          if (this.pdf.main_type == "ปพ") {
            this.result = createDoc1(this.info, this.pdf);
          } else {
            this.result = createDoc2(this.info, this.pdf);
          }
          window.open(this.result.output("bloburl"), "_self");
        } else {
          console.log("No data available");
          this.txt = "ไม่พบข้อมูล";
        }
      })
      .catch((error) => {
        console.error(error);
      });
  },
};
</script>

<style>
</style>